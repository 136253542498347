





































































































































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class productFileDetail extends Vue {
    form: any = {
        name: "",
        region: "",
        no: "123141231232",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
    };
    tableData: any[] = [
        {
            assignee: "19843",
            assigneesName: "试用(test0809)",
            actName: "申请人",
            approveStatus: 3,
            approveStatusName: "流程启动",
            comment: "提交申请",
            createAt: "2023-08-31 16:29:07",
            updateAt: "2023-08-31 16:29:07",
            status: 0,
            lastApproveTime: "2023-08-31 16:29:07",
            delayedTime: 0,
        }
    ];
    /** S Methods **/
}
