var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ls-add-admin"},[_c('div',{staticClass:"ls-card"},[_c('el-page-header',{attrs:{"content":"添加订单修改"},on:{"back":function($event){return _vm.$router.push('/card/makeCard/orderEdit')}}})],1),_c('div',{staticClass:"ls-card m-t-16"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"200px"}},[_c('el-form-item',[_c('span',{staticStyle:{"font-weight":"bold","color":"rgb(0, 128, 128)"},attrs:{"slot":"label"},slot:"label"},[_vm._v(" 运营商名称 ")]),_vm._v(" 运营商名称 ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"申请人"}},[_vm._v(" 申请人 ")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"申请时间"}},[_vm._v(" 2023-08-21 17:25:37 ")])],1)],1),_c('el-form-item',{attrs:{"label":"客户名称 ","rules":[
                    {
                        required: true,
                        message: '请输入客户名称',
                        trigger: 'change',
                    } ]}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form.desc),callback:function ($$v) {_vm.$set(_vm.form, "desc", $$v)},expression:"form.desc"}})],1),_c('el-form-item',{attrs:{"label":"订单编号 ","rules":[
                    {
                        required: true,
                        message: '请输入订单编号',
                        trigger: 'change',
                    } ]}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form.ddbh),callback:function ($$v) {_vm.$set(_vm.form, "ddbh", $$v)},expression:"form.ddbh"}})],1),_c('el-form-item',{attrs:{"label":"产品名称 ","rules":[
                    {
                        required: true,
                        message: '请输入产品名称',
                        trigger: 'change',
                    } ]}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.form.cpmc),callback:function ($$v) {_vm.$set(_vm.form, "cpmc", $$v)},expression:"form.cpmc"}})],1),_c('el-form-item',{attrs:{"label":"产品类型 ","rules":[
                    {
                        required: true,
                        message: '请选择产品类型',
                        trigger: 'change',
                    } ]}},[_c('el-radio-group',{attrs:{"disabled":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("电影次票券")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("指定品类储值券")]),_c('el-radio',{attrs:{"label":3}},[_vm._v("N选1兑换券")]),_c('el-radio',{attrs:{"label":4}},[_vm._v("指定商品储值券 ")]),_c('el-radio',{attrs:{"label":5}},[_vm._v("全场通用储值券")])],1)],1),_c('el-form-item',{attrs:{"label":"修改类型","rules":[
                    {
                        required: true,
                        message: '请选择修改类型',
                        trigger: 'change',
                    } ]}},[_c('el-checkbox-group',{attrs:{"disabled":""},model:{value:(_vm.form.xglx),callback:function ($$v) {_vm.$set(_vm.form, "xglx", $$v)},expression:"form.xglx"}},[_c('el-checkbox',{attrs:{"label":"修改积分价值"}}),_c('el-checkbox',{attrs:{"label":"修改线下核销选项"}}),_c('el-checkbox',{attrs:{"label":"修改适用商品及banner"}}),_c('el-checkbox',{attrs:{"label":"修改主品类"}}),_c('el-checkbox',{attrs:{"label":"前端产品名称"}}),_c('el-checkbox',{attrs:{"label":"修改使用规则"}}),_c('el-checkbox',{attrs:{"label":"修改跳转类型"}}),_c('el-checkbox',{attrs:{"label":"修改适用范围"}}),_c('el-checkbox',{attrs:{"label":"修改券有效期"}}),_c('el-checkbox',{attrs:{"label":"修改激活日期"}})],1)],1),_c('el-form-item',{attrs:{"label":"操作原因"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.resource),callback:function ($$v) {_vm.$set(_vm.form, "resource", $$v)},expression:"form.resource"}})],1),_c('el-form-item',{attrs:{"label":"节点流程"}},[_c('el-table',{attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"actName","label":"节点名称"}}),_c('el-table-column',{attrs:{"prop":"assigneesName","label":"执行人"}}),_c('el-table-column',{attrs:{"prop":"approveStatusName","label":"状态"}}),_c('el-table-column',{attrs:{"prop":"createAt","label":"开始时间"}}),_c('el-table-column',{attrs:{"prop":"updateAt","label":"结束时间"}}),_c('el-table-column',{attrs:{"prop":"delayedTime","label":"节点耗时"}}),_c('el-table-column',{attrs:{"prop":"comment","label":"处理意见","min-width":"400"}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }